import React from 'react';
import "./badge.scss";

const BadgeTypes = {
    Success : "success",
    Pending : "primary",
    Initiate : "warning",
    Incomplete : "danger",
    Failed : "danger",
    Danger: "danger"
  }

const Badge = (props) => {
    const {value, type} = props;
    return (
      <>
        <span className={`badge badge-${BadgeTypes[type]}`}>
            {value}
        </span>
      </>
    );
};

export default Badge;