import React, { useEffect, useState } from 'react';
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import SubHeader from "../../components/SubHeader";
import "./editSettings.scss";
import Form from "../../components/Forms";
import {formFields, buttons} from "./data";
import { useNavigate, useParams } from "react-router-dom";

function EditSettings() {
    const navigate = useNavigate();

    const [fields, updateFormFields] = useState(formFields);
    const [uploadSuccess, updateUploadSuccess] = useState(false);
    const [uploadError, updateUploadFailure] = useState("");
    const [settings, updateSettings] = useState({});

    const {id} = useParams();

    const updateValuesToEdit = (settings) => {
        const currentSettings = {...settings};
        const {mappedUpis} = currentSettings;
        mappedUpis && mappedUpis.forEach(upi => {
            currentSettings[`upiLevel${upi.priority}`] = upi.upi;
        });
        updateSettings(currentSettings);
    }

    const fetchData = () => {
        let settings = localStorage.getItem("settings");
        localStorage.removeItem("settings");
        settings = JSON.parse(settings);
        updateSettings(settings);
        
        const formFields = [...fields];
        formFields.forEach(field => {
            if(field.name.includes("upiLevel")) {
                field.options = [{label: "Select UPI Id", value: ""}];
                settings.availableUpis.forEach(option => {
                    field.options.push({label: option, value: option});
                });
                settings.mappedUpis.forEach(option => {
                    field.options.push({label: option.upi, value: option.upi});
                });
            }
        });
        updateFormFields(formFields);
        setTimeout(() => updateValuesToEdit(settings),500);
    }

    useEffect(()=> {
        fetchData();
    }, []);

    const handleFormSubmit = (data) => {
        updateUploadFailure("");
        updateUploadSuccess(false);

        const { category } = data;

        for (let i=1; i<=10; i++) {
            if(data[`upiLevel${i}`] != "" && data[`upiLevel${i-1}`] == "") {
                updateUploadFailure(`Value of upi id ${i-1} cannot be left blank`);
                return;
            }
        }

        const body = {
            category,
            mappedUpis: []
        };
        const mappedUPI =  {
            accountStatus: "active",
            activeCategory: false,
            priority: 0,
            upi: ""
        }
        for (var key in data) {
            if(key.includes("upiLevel") &&  data[key]) {
                const upi = {...mappedUPI};
                upi.upi = data[key];
                upi.priority = key.split("upiLevel")[1];
                upi.activeCategory = true;
                body.mappedUpis.push(upi);
            }
        }

        let url = `${env.apiDomain}${ApiPaths.editSettings}`
        const options = {
            url,
            requestBody: body,
        }
        API.post(options).then(data => {
            updateUploadSuccess(true);
            setTimeout(()=> {
                navigate("/dashboard/settings")
            }, 1000);
        }).catch(error =>  {
            updateUploadFailure(error.message);
        });
    }

    return (
        <div className="edit_settings">
            <SubHeader pageHeader="Settings Management" />
            <div className="card_container">
                <h5>Edit Settings</h5>
                <Form 
                    formFields = {fields}
                    buttons= {buttons}
                    onSubmit={handleFormSubmit}
                    defaultValues={settings}
                />
                {uploadError && <div className="upload_error">{uploadError ? uploadError : "Something went wrong"}</div>}
                {uploadSuccess && <div className="upload_success">{uploadSuccess ? "UPI updated Successfully" : ""}</div>}
            </div>
        </div>
    );
}

export default EditSettings;
