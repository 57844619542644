export const defaultFilters = {
    account_id: ""
};

export const filterConfig = [
    {
        name: "daterange",
        type: "dateRange",
        label: "Date Range",
        width: "230px",
        placeholder: "Date Range"
    }
]


