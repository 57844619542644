import React, { Fragment, useEffect, useState, useCallback } from 'react';
import "./unclaimedReports.scss";
import { defaultFilters, mockData, columns, filterConfig, defaultPagination} from "./data";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import Grid from "../../components/Grid";
import SubHeader from "../../components/SubHeader";
import Paginate from "../../components/Pagination";
import Filters from "../../components/Filters";

function UnclaimedReports() {

    const [filters, setFilters] = useState({...defaultFilters});
    const [records, setRecords] = useState({statementRecords : [], totalTransactions: 0});
    const [pagination, setPagination] = useState({...defaultPagination});
    const [filtersArr, updateFiltersArr] = useState(filterConfig);

    const fetchData = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.statementRecords}`, 
            queryObject: filters
        }
        API.get(options).then(data => {
            setRecords(data);
        }).catch(error => {
            console.log(error)
        });
    }

    const updateAccountsFilterConfig = (accounts) => {
        const currentFilterArr = [...filtersArr];
        currentFilterArr.forEach(filter => {
            if(filter.name == "accountName") {
                filter.options = [
                    {label: "Select Account", value: ""},
                    {label: "All Accounts", value: ""},
                ];
                accounts && accounts.forEach(account => {
                    filter.options.push({label: account, value: account},)
                })
            }
        });
        updateFiltersArr(currentFilterArr); 
    }

    const updateBanksFilterConfig = (banks) => {
        const currentFilterArr = [...filtersArr];
        currentFilterArr.forEach(filter => {
            if(filter.name == "bankId") {
                filter.options = [
                    {label: "Select Bank", value: ""},
                    {label: "All Banks", value: ""},
                ];
                banks && banks.forEach(bank => {
                    filter.options.push({label: bank, value: bank},)
                })
            }
        });
        updateFiltersArr(currentFilterArr); 
    }

    const updateFiltersConfig = (filterMetaData) => {
        const {accounts, banks} = filterMetaData;
        updateAccountsFilterConfig(accounts);
        updateBanksFilterConfig(banks);
    }

    const fetchFilterMetaData = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.statementMetaData}`
        }
        API.get(options).then(data => {
            updateFiltersConfig(data);
        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(()=> {
        fetchData();
        fetchFilterMetaData();
    }, []);

    const handleSortingChange = useCallback(sortObj => {
        if(!sortObj.length) {
            return;
        }
        const {id, desc} = sortObj[0];
        filters.sortBy = id;
        filters.sortOrder = desc ? "desc" : "asc";
        setFilters(filters);
        fetchData();
    }, []);

    const handlePaginationChange = (paginationObj) => {
        const {pageNumber, pageSize} = paginationObj;
        setPagination(paginationObj);
        filters.pageNumber = (pageNumber-1).toString();
        filters.pageSize = pageSize;
        setFilters(filters);
        fetchData();
    }

    const applyFilter = (filterObj) => {
        setPagination(defaultPagination);
        setFilters({...filterObj, ...defaultPagination });
        fetchData();
    }

    return (
        <Fragment>
            <SubHeader pageHeader="Unclaimed Record" />
            <div className="card_container">
                <Filters 
                    config = {filtersArr}
                    defaultFilters={filters}
                    applyFilter = {applyFilter}
                />
                <Grid
                    columns={columns}
                    data = {records.statementRecords}
                    manualSortBy={true}
                    handleSortingChange={handleSortingChange}
                />
                <div className="paginate_container">
                    <Paginate 
                        handleChange = {handlePaginationChange}
                        totalRecords = {records.totalTransactions}
                        pageSize = {pagination.pageSize}
                        currentPage = {filters.pageNumber+1}
                    />
                </div>
            </div>            
        </Fragment>
    );
}

export default UnclaimedReports;
