export const formFields = [
    {
        name: "bankName",
        type: "select",
        width: "230px",
        options: [
            {label: "Select Bank", value: ""}
        ],
        validations: [
            {
                type: "required",
                errorMessage: "Please select Bank Name"
            }
        ]
    },
    {
        name: "statemetn",
        type: "file",
        width: "230px",
        accept:".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        placeholder: "Choose File",
        validations: [
            {
                type: "required",
                errorMessage: "Please choose a file"
            }
        ]
    }
];

export const buttons = [
    {
        name: "",
        type: "submit",
        label: "Submit",
        cssClass:"",
        handleClick: ()=>{} 
    }
];