import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "./utrPay.scss";
import { ApiPaths } from "../../configs/apiConfig";
import { env } from "../../configs/env";
import API from "../../utilities/Api";
import { localization } from "./data";
import axios from 'axios';

function UTRPay() {
    const navigate = useNavigate();
    const { id } = useParams();
    console.log("id", id);
    const [locale, updateLocale] = useState("en");
    const [textMap, updateTextMap] = useState(localization[locale]);
    const [metaData, setMetaData] = useState({});
    const [timer, updateTimer] = useState({ minutes: 0, seconds: 0 });
    const [utrValue, updateUTRValue] = useState("");
    const [submitError, updateSubmitError] = useState("");

    let interval = null;
    let timeDuration = 301;
    const inputRef = useRef(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${env.apiDomain}${ApiPaths.getDetails}/${id}`);
            console.log(response.data);
            setMetaData(response.data);

            // Handle the response data
        } catch (error) {
            console.error(error);
            // Handle any errors that occurred during the request
        }


        //     let data = localStorage.getItem("_t");
        //     if(data){
        //         data = atob(data);
        //     console.log("data",data);
        //     data = JSON.parse(data);
        // }
        // else setMetaData({})
    }

    const postOrderFreezeAPI = async () => {
        // let data = localStorage.getItem("_t");
        // data = atob(data);
        // data = JSON.parse(data);
        // let data=metaData;
        // const options = {
        //     url: `${env.apiDomain}${ApiPaths.freezeOrder}`,
        //     requestBody: {
        //         orderId: data?.orderId,
        //         txnId: id
        //     },
        //     queryObject:{
        //         id
        //     }
        // }
        // API.post(options).then(data => {
        //     console.log(data);
        // }).catch(error =>  {
        //     console.log(error);
        // });

        try {
            const response = await axios.post(`${env.apiDomain}api/order/freeze`, {

                orderId: metaData.orderId,
                txnId: id

            });
            console.log(response.data);
            window.history.back();

            // Handle the response data
        } catch (error) {
            console.error(error);
            window.history.back();
            // Handle any errors that occurred during the request
        }
    }

    const getTime = () => {
        timeDuration--;
        if (timeDuration < 0) {
            clearInterval(interval);
            postOrderFreezeAPI();
            return;
        }
        let minutes = parseInt(timeDuration / 60);
        let seconds = timeDuration % 60;
        updateTimer({ minutes, seconds });
    };

    useEffect(() => {
        fetchData();
        interval = setInterval(() => getTime(), 1000);
        return () => clearInterval(interval);
    }, []);

    const updateLanguage = (e) => {
        const language = e.target.id;
        updateLocale(language);
        updateTextMap(localization[language])
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(metaData.upiId);
    }

    const handleUTRValueChange = (value) => {
        if (value.length > 12) {
            updateSubmitError("The length of UTR can be max 12 digits");
        } else {
            updateUTRValue(value);
        }
    }

    const submitUTR = () => {
        updateSubmitError("");
        if (utrValue == "") {
            updateSubmitError("UTR Number is required");
            inputRef.current.focus();
            inputRef.current.scrollIntoView();
            return;
        } else if (utrValue.length != 12) {
            updateSubmitError("UTR Number should be 12 digits long");
            inputRef.current.focus();
            inputRef.current.scrollIntoView();
            return;
        }
        const options = {
            url: `${env.apiDomain}${ApiPaths.submitUTR}`,
            requestBody: {
                orderId: metaData.orderId,
                txnId: metaData.txnId,
                utrNumber: utrValue
            },
            includeAuth: false
        }
        API.post(options).then(data => {
            // window.location.href = data.redirectUrl || window.location.href;
            navigate("/utr-thanks")
        }).catch(error => {
            updateSubmitError("Something went wrong");
        });
    }

    return (
        <>
            <div className="hidden lg:flex justify-center items-center container">
                <div className="main self-center">
                    <div className="top_color" />
                    <div className="heading ">
                        <span>{textMap["heading"]}</span>
                    </div>
                    <div className="lang_list" onClick={updateLanguage}>
                        <span id="en" class="selected">En</span>
                        <span id="tamil" class="">தா</span>
                        <span id="telgu" class="">తెలుగు</span>
                    </div>
                    <div className='grid grid-cols-3 gap-4'>
                        <div>
                            <div className="payment_info">
                                <div className="ref_info">
                                    <span className="ref">
                                        <span>{textMap["ref_info"]} </span>
                                        <b>{metaData.txnId}</b>
                                    </span>
                                    <span className="timer">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/time20.png" styleName="vertical-align: bottom;" />
                                        <span className="time">{`${timer.minutes} : ${timer.seconds}`}</span>
                                    </span>
                                </div>
                                {(timer.minutes > -1 && timer.seconds > -1) ? (
                                    <div className="qr_container">
                                        <div className="title_text">{textMap["title_text"]}</div>
                                        <img className="qr_image" src={metaData.qrLocation} alt="qr" styleName="padding-bottom: 15px" />
                                        <div className="download_container">
                                            <a className="downloadQR" href={metaData.qrLocation} download="qr" target="_blank">Save QR</a>
                                        </div>
                                    </div>) : null}
                                <div className="upi_container">
                                    <span className="upi">{metaData.upiId}</span>
                                    <span className="copy_btn" onClick={copyToClipboard}>
                                        <img src="https://pay.smartpg.in/ajdesign/img/copy24.png" styleName="vertical-align: bottom;flex: 1;cursor: pointer" />
                                    </span>
                                </div>
                                <div className="line_break">
                                    <hr />
                                </div>
                                <div className="amount_container">
                                    <div className="amount">
                                        <h2>{`₹ ${metaData.amount}`}</h2>
                                    </div>
                                    <div className="warning">Don't use the same QR code to pay multiple times</div>
                                </div>
                            </div>
                            <div className="helper">
                                <span>{textMap["upi_helper"]} </span>
                                <span className="click_here">{textMap["click_cta"]}</span>
                            </div>
                        </div>

                        <div className='grid place-items-center w-full'>
                            <div className='w-[70%]'>

                                <div className="input_form w-full">
                                    <input className='w-20' ref={inputRef} placeholder="Enter 12 Digit UTR Number" type="text" value={utrValue} onChange={(e) => handleUTRValueChange(e.target.value)} />
                                    <p className="submit_error">{submitError}</p>
                                </div>
                                <div className="upi_list flex items-center justify-center">
                                    <a className="gpay" href={metaData.gpay} target="_balnk">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/gpay.png" />
                                    </a>
                                    <a className="paytm" href={metaData.paytm} target="_balnk">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/paytm.png" />
                                    </a>
                                    <a className="phonepe" href={metaData.phonepe} target="_balnk">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/phonepe.png" />
                                    </a>
                                    <a className="rupe" href={metaData.rupe} target="_balnk">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/rupe.png" />
                                    </a>
                                    <a className="mobiwik" href={metaData.mobiwik} target="_balnk">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/mobi.png" />
                                    </a>
                                    <a className="bharatpe" href={metaData.bharatpe} target="_balnk">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/bharat pe.png" />
                                    </a>
                                    <a className="icici" href={metaData.icici} target="_balnk">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/icici.png" />
                                    </a>
                                    <a className="freecharge" href={metaData.paytm} target="_balnk">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/free.png" />
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div className='grid place-content-center'>
                            <div className="description">
                                <span>{textMap["upi_description"]}</span>
                            </div>

                            <div className="next_steps">
                                <h3>Important Note:</h3>
                                <div className="note_container">
                                    <span className="image_container">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/scan24.png" styleName="vertical-align: bottom;" />
                                    </span>
                                    <span className="note_text">{textMap["note1"]}</span>
                                </div>
                                <div className="note_container">
                                    <span className="image_container">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/card24.png" styleName="vertical-align: bottom;" />
                                    </span>
                                    <span className="note_text">{textMap["note2"]}</span>
                                </div>
                                <div className="note_container">
                                    <span className="image_container">
                                        <img src="https://pay.smartpg.in/ajdesign2/img/ticket24.png" styleName="vertical-align: bottom;" />
                                    </span>
                                    <span className="note_text">{textMap["note3"]}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="secure_list">
                        <h6 className="secure_text">{textMap["secure_text"]}</h6>
                        <div className="img_container">
                            <img className="parnter_img" src="https://pay.smartpg.in/ajdesign2/img/norton.png" />
                            <img className="parnter_img" src="https://pay.smartpg.in/ajdesign2/img/visa.png" />
                            <img className="parnter_img" src="https://pay.smartpg.in/ajdesign2/img/mastercard.png" />
                            <img className="parnter_img" src="https://pay.smartpg.in/ajdesign2/img/pci64.png" />
                            <img className="parnter_img" src="https://pay.smartpg.in/ajdesign2/img/safekey.png" />
                        </div>
                    </div>
                    <div className="spacer" />
                </div>
                <div className="submit_button">
                    <button type="button" onClick={submitUTR}>{textMap["button"]}</button>
                </div>
            </div>



            <div className='relative lg:hidden  block'>

                <div className=''>
                    <div className='h-52'>

                        <div className='top_color'>
                        </div>
                        <div className="flex w-full items-start justify-end gap-10  text-4xl text-white mt-1  " onClick={updateLanguage}>
                            <span id="en" class="selected">En</span>
                            <span id="tamil" class="">தா</span>
                            <span id="telgu" class="">తెలుగు</span>
                        </div>
                        <div className="w-full text-center mt-5 text-xl text-white ">
                            <span className='text-5xl'>{textMap["heading"]}</span>
                        </div>
                    </div>

                </div>
                <div className='flex w-full items-center justify-center'>
                    {/* QR box */}
                    <div className=" bg-blue-100 px-10 py-10 rounded-xl w-[90%] self-center">
                        <div className="flex w-full justify-around  ">
                            <span className="text-4xl text-black mt-7 ">
                                <span>{textMap["ref_info"]} </span>
                                <b>{metaData.txnId}</b>
                            </span>
                            <span className="text-4xl mt-3 grid place-content-center">
                                <img className="w-20 " src="https://pay.smartpg.in/ajdesign2/img/time20.png" styleName="vertical-align: bottom;" />
                                <span className="">{`${timer.minutes} : ${timer.seconds}`}</span>
                            </span>
                        </div>
                        {(timer.minutes > -1 && timer.seconds > -1) ? (
                            <div className=" ">
                                <div className="text-5xl font-medium my-6 text-center">{textMap["title_text"]}</div>
                                <img className="qr_image" src={metaData.qrLocation} alt="qr" styleName="padding-bottom: 15px" />
                                <div className="download_container text-center my-20   ">
                                    <a className="text-4xl px-9 py-4 rounded-full bg-blue-700 text-white " href={metaData.qrLocation} download="qr" target="_blank">Save QR</a>
                                </div>
                            </div>) : null}
                        <div className="w-full flex items-center justify-center  ">
                            <span className="text-6xl text-blue-800 ">{metaData.upiId}</span>
                            <span className="px-3" onClick={copyToClipboard}>
                                <img className='text-blue-800 w-20' src="https://pay.smartpg.in/ajdesign/img/copy24.png" styleName="vertical-align: bottom;flex: 1;cursor: pointer" />
                            </span>
                        </div>
                        <div className="line_break">
                            <hr />
                        </div>
                        <div className="grid text-5xl gap-2 text-blue-800 text-center">
                            <div className=" font-semibold">
                                <h2>{`₹ ${metaData.amount}`}</h2>
                            </div>
                            <div className="warning text-3xl">Don't use the same QR code to pay multiple times</div>
                        </div>
                    </div>



                </div>

                {/* input */}
                <div className="input_form text-center my-7 w-full ">
                    <div className='text-center my-7 w-full flex items-center justify-center'>

                    <input className=' w-[90%] text-center text-5xl py-10 ' ref={inputRef} placeholder="Enter 12 Digit UTR Number" type="text" value={utrValue} onChange={(e) => handleUTRValueChange(e.target.value)} />
                    </div>
                    <p className="submit_error">{submitError}</p>
                </div>
                {/* list */}

                <div className="upi_list flex  items-center justify-around py-6">
                    <a className="gpay" href={metaData.gpay} target="_balnk">
                        <img className='w-24' src="https://pay.smartpg.in/ajdesign2/img/gpay.png" />
                    </a>
                    <a className="paytm" href={metaData.paytm} target="_balnk">
                        <img className='w-24' src="https://pay.smartpg.in/ajdesign2/img/paytm.png" />
                    </a>
                    <a className="phonepe" href={metaData.phonepe} target="_balnk">
                        <img className='w-24' src="https://pay.smartpg.in/ajdesign2/img/phonepe.png" />
                    </a>
                    <a className="rupe" href={metaData.rupe} target="_balnk">
                        <img className='w-24' src="https://pay.smartpg.in/ajdesign2/img/rupe.png" />
                    </a>
                    <a className="mobiwik" href={metaData.mobiwik} target="_balnk">
                        <img className='w-24' src="https://pay.smartpg.in/ajdesign2/img/mobi.png" />
                    </a>
                    <a className="bharatpe" href={metaData.bharatpe} target="_balnk">
                        <img className='w-24' src="https://pay.smartpg.in/ajdesign2/img/bharat pe.png" />
                    </a>
                    <a className="icici" href={metaData.icici} target="_balnk">
                        <img className='w-24' src="https://pay.smartpg.in/ajdesign2/img/icici.png" />
                    </a>
                    <a className="freecharge" href={metaData.paytm} target="_balnk">
                        <img className='w-24' src="https://pay.smartpg.in/ajdesign2/img/free.png" />
                    </a>
                </div>

                <div className="  w-full text-5xl fixed bottom-0 px-2 ">
                    <button type="button" className=' w-full rounded-xl text-5xl px-10 py-5 bg-blue-800 text-white' onClick={submitUTR}>{textMap["button"]}</button>
                </div>

            </div>

        </>

    );
}

export default UTRPay;
