import React, { useState, Fragment, useEffect } from 'react';
import "./allReports.scss";
import {defaultFilters, filterConfig} from "./data";
import moment from "moment";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import SubHeader from "../../components/SubHeader";
import Filters from "../../components/Filters";

function AllReports() {
    const [filters, setFilters] = useState({...defaultFilters});
    const [filtersArr, updateFiltersArr] = useState(filterConfig);

    const downloadReport = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.exportAllTransactions}`,
            queryObject: filters
        }
        API.download(options).then(blob => {
            let file = window.URL.createObjectURL(blob);
            window.location.assign(file);
        }).catch(error => {
            console.log(error)
        });
    }

    const applyFilter = (filterObj) => {
        if (filterObj.daterange) {
            filterObj.startDateTime = filterObj.daterange[0];
            filterObj.endDateTime = filterObj.daterange[1];
            filterObj.dateRange == null;
        } else {
            filterObj.startDateTime = null;
            filterObj.endDateTime = null;
        }
        setFilters(filterObj);
        downloadReport();
    }

    return (
        <Fragment>
            <SubHeader pageHeader="All Reports" />
            <div className="card_container statement_management">
                <Filters 
                    config = {filtersArr}
                    defaultFilters={filters}
                    applyFilter = {applyFilter}
                    button="download"
                />
            </div>            
        </Fragment>
    )
}

export default AllReports;