import React from 'react';
import "./card.scss";

function Card (props) {
    const {title, value} = props;

    return (
        <div className="card_component">
            <div className="title">{title}</div>
            <div className="value">{value}</div>
        </div>
    )
}

export default Card;