import React from 'react';

import moment from "moment";

export const defaultPagination = {
    pageNumber: "0",
    pageSize: 50
};

export const defaultFilters = {
    amount: "", 
    category: "", 
    orderId: "", 
    pageNumber: defaultPagination.pageNumber, 
    pageSize: defaultPagination.pageSize, 
    referenceNumber: "", 
    status: "Failed", 
    utr: "",
    sortBy: "id",
    sortOrder: "desc",
    startDateTime: "",
    endDateTime: ""
};

export const filterConfig = [
  {
    name: "daterange",
    type: "dateRange",
    label: "Date Range",
    width: "230px",
    startDateTime: "",
    endDateTime: "",
    placeholder: "Date Range"
  }
]

export const columns = [
    {
      Header: "SR No",
      accessor: 'id',
      width: "24px"
    },
    {
      Header: "Order ID",
      accessor: 'orderId',
      width: "154px"
    },
    {
      Header: "Amount",
      accessor: 'amount',
      width: "154px"
    },
    {
      Header: "Feedback",
      accessor: 'feedback',
      width: "154px"
    }
];