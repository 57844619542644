import React, { useState, Fragment, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "./requestForm.scss";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import Forms from "../../components/Forms";
import SubHeader from "../../components/SubHeader";
import {formFields, buttons} from "./data";

function RequestForm() {
    const navigate = useNavigate();
    const { id } = useParams();

    const handleFormSubmit = (data) => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.createOrder}`,
            requestBody: data,
            includeAuth: false
        }
        API.post(options).then(data => {
            // let response = JSON.stringify(data);
            // const status= response.status;

            // console.log("response",data.redirectUrl);
            // response = btoa(response);
            // localStorage.setItem("_t",response);
            // navigate(response.redirectUrl)
            window.location.href = data.redirectUrl;
        }).catch(error =>  {
            console.log(error);
        });
    };

    return (
        <div className="request_form">
            <SubHeader pageHeader="Request Form" />
            <div className="card_container">
                <Forms 
                    formFields = {formFields}
                    parentClass=""
                    buttons= {buttons}
                    onSubmit={handleFormSubmit}
                />
            </div>            
        </div>
    )
}

export default RequestForm;