import React, { Fragment, useEffect, useState, useCallback } from "react";
import "./transactions.scss";
import {
    defaultFilters,
    defaultPagination,
    columns,
    filterConfig,
} from "./data";
import { ApiPaths } from "../../configs/apiConfig";
import { env } from "../../configs/env";
import API from "../../utilities/Api";
import Grid from "../../components/Grid";
import SubHeader from "../../components/SubHeader";
import Paginate from "../../components/Pagination";
import Filters from "./components/filters";
import axios from "axios";
import { prepareAuthHeader } from "../../utilities/utils";
import { Navigate } from "react-router-dom";
import Table from "./components/table";

function Transactions() {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState();
    const [returnedFilters, setReturnedFilters] = useState({});
    const [sort, setSort] = useState({ field: "id", direction: "desc" });
    const [pagination, setPagination] = useState({ pageNumber: 0, pageSize: 50 })
    const fetchData = async () => {
        try {
            const params = {
                ...returnedFilters,
                pageSize: pagination.pageSize,
                sortBy: sort.field,
                pageNumber: pagination.pageNumber,
                sortOrder: sort.direction,
            };
            setIsLoading(true);
            const response = await axios.get(
                `${env.apiDomain}${ApiPaths.transactions}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        ...prepareAuthHeader(),
                    },
                    params: params,
                }
            );
            setIsLoading(false);
            setData(response.data);
        } catch (error) {
            // Handle error if API request fails
            if (error.status == 401) {
                Navigate("/login");
            }
            setIsError(true);
            setError(error.message);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        console.log(returnedFilters);
        fetchData();
    }, [returnedFilters, sort, pagination]);


    const filters = [
        {
            name: "status",
            type: "select",
            options: [
                "All Status",
                "Initiate",
                "Incomplete",
                "Pending",
                "Success",
                "Failed",
            ],
            defaultOption: "Select Status",
        },
        {
            name: "category",
            type: "select",
            options: ["All Category", "P4", "P3", "P2", "P1"],
            defaultOption: "Select Category",
        },
        {
            name: "utr",
            type: "input",
            placeholder: "UTR",
        },
        {
            name: "amount",
            type: "input",
            placeholder: "Amount",
        },
        {
            name: "orderId",
            type: "input",
            placeholder: "Order Id",
        },
        {
            name: "startDate",
            type: "min-date",
            placeholder: "Min Date",
        },
        {
            name: "endDate",
            type: "max-date",
            placeholder: "Max Date",
        },
        {
            type: "submit",
            classNames: "bg-green-400 self-center h-10 px-6",
        },
    ];

    const TableHeaders = [
        {
            name: "SR. No",
            sortable: true,
            id: "id"
        },
        {
            name: "Order Id",
            sortable: true,
            id: "orderId"
        },
        {
            name: "UTR",
            sortable: true,
            id: "statementTransactionNumber"

        },
        {
            name: "Amount",
            sortable: true,
            id: "amount"
        },
        {
            name: "Status",
            sortable: true,
            id: "status",
        },
        {
            name: "Remark",
            sortable: false,
        },
        {
            name: "Message",
            sortable: true,
            id: "message"
        },
        {
            name: "Category",
            sortable: false,
        },
        {
            name: "Bank Account",
            sortable: false,
        },
        {
            name: "Force Convert Status",
            sortable: false,
        },
        {
            name: "Date",
            sortable: true,
            id: "createdAt"
        },
    ];

    const handlePaginationChange = (paginationObj) => {
        const { pageNumber, pageSize } = paginationObj;

        setPagination({ pageNumber: (pageNumber - 1).toString(), pageSize: pageSize });

    }

    return (
        <Fragment>
            <SubHeader pageHeader="Transactions" />
            <Filters filters={filters} setReturnedFilters={setReturnedFilters} />
            <Table
                wrapperClassName=" no-scrollbar"
                dataCount={data?.transactions?.length}
                isLoading={isLoading}
                isError={isError}
                error={error}
            >
                <Table.Thead>
                    <Table.Tr>
                        {TableHeaders.map((header, index) => {
                            return <Table.Th key={index} sortable={header.sortable} sort={sort} setSort={setSort} name={header?.id} >
                                {header.name}
                            </Table.Th>
                        })}
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {
                        data?.transactions?.map((record, index) => <Row record={record} key={index} fetchData={fetchData} />)
                    }
                </Table.Tbody>
            </Table>

            <div className="paginate_container pb-10">
                <Paginate
                    handleChange={handlePaginationChange}
                    totalRecords={data?.totalTransactions}
                    pageSize={pagination.pageSize}
                    currentPage={filters.pageNumber + 1}
                />
            </div>
        </Fragment>
    );
}
const Row = ({ record, fetchData }) => {

    const handleStausChange = (e) => {

        if (e.target.value != "default") {
            const query = {
                status: e.target.value,
                id: record.id
            }
            const options = {
                url: `${env.apiDomain}${ApiPaths.changeTransactionStatus}`,
                queryObject: query
            }
            API.get(options).then(data => {
                record.status = data.status;
                fetchData();
            }).catch(error => {
                console.log(error)
            });
        }


    }
    return (
        <Table.Tr>
            <Table.Td>
                {
                    <div>
                        <dd>{record.id}</dd>
                    </div>
                }
            </Table.Td>
            <Table.Td>
                <dd className="capitalize">{record.orderId}</dd>
            </Table.Td>
            <Table.Td className="font-semibold">{record.statementTransactionNumber}</Table.Td>
            <Table.Td>
                <dd> {record.amount} </dd>
            </Table.Td>

            <Table.Td>
                <Badge status={record.status} />
                {/* <dd>{record.status}</dd> */}
            </Table.Td>

            <Table.Td>
                <dd>{record.note}</dd>
            </Table.Td>

            <Table.Td>
                <dd>{record.message}</dd>
            </Table.Td>

            <Table.Td>
                <dd>{record.category}</dd>
            </Table.Td>

            <Table.Td>
                <dd>{record.bankAccountId}</dd>
            </Table.Td>

            <Table.Td>
                <select className='filters_dropdown w-32 ' onChange={handleStausChange} >
                    <option value='default' >Select Status</option>
                    <option value='Success' >Success</option>
                    <option value="Failed" >Fail</option>
                </select>
            </Table.Td>

            <Table.Td>
                <dd>{record.createdAt.slice(0, 10)}</dd>
            </Table.Td>

        </Table.Tr>
    );
};

const Badge = ({ status }) => {
    if (status == "Success") {
        return (
            <span className=" h-5 w-16 badge badge-pill badge-success">{status}</span>

        )
    }
    else if (status == "Failed") {
        return (
            <span className=" h-5 w-16 badge badge-pill badge-danger">{status}</span>

        )
    }
    else if (status == "Initiate") {
        return (
            <span className=" h-5 w-16 badge badge-pill badge-warning">{status}</span>

        )
    }
    else if (status == "Incomplete") {
        return (
            <span className=" h-5 w-16 badge badge-pill badge-info">{status}</span>

        )
    }
    else if (status == "Fail") {
        return (
            <span className=" h-5 w-16 badge badge-pill badge-danger">{status}</span>

        )
    }

    else {
<></>
    }
}


export default Transactions;
{/* <span className=" h-10 badge badge-pill badge-secondary">Secondary</span>
<span className=" h-10 badge badge-pill badge-success">Success</span>
<span className=" h-10 badge badge-pill badge-danger">Danger</span>
<span className=" h-10 badge badge-pill badge-warning">Warning</span>
<span className=" h-10 badge badge-pill badge-info">Info</span>
<span className=" h-10 badge badge-pill badge-light">Light</span>
<span className=" h-10 badge badge-pill badge-dark">Dark</span> */}