import moment from "moment";
import React from 'react';

export const defaultPagination = {
    pageNumber: "0",
    pageSize: 50
};

export const defaultFilters = {
    pageNumber: defaultPagination.pageNumber, 
    pageSize: defaultPagination.pageSize, 
};

export const columns = [
    {
      Header: "SR No",
      accessor: 'id',
      width: "24px",
      disableSortBy: true
    },
    {
      Header: "Total Transactions",
      accessor: 'totalTransactions',
      width: "154px",
      disableSortBy: true
    },
    {
      Header: "Total Abandoned Transactions",
      accessor: 'incompleteTransactions',
      width: "130px",
      disableSortBy: true
    },
    {
      Header: "Pending",
      accessor: 'pending',
      width: "64px",
      disableSortBy: true
    },
    {
      Header: "Success",
      accessor: 'success',
      width: "64px",
      disableSortBy: true
    },
    {
      Header: "Failed",
      accessor: 'failed',
      width: "64px",
      disableSortBy: true
    },
    {
      Header: "Success Rate",
      accessor: 'successRate',
      width: "70px",
      disableSortBy: true
    },
    {
      Header: "Date / Time",
      accessor: 'time',
      width: "80px",
      Cell: ({ cell: { value } }) => <span>{moment(value).format("yyyy-MM-DD")}  {value.slice(11,19)}</span>,
      disableSortBy: true
    }
];