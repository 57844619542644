export const metaData = [
    {
        title: "Today Earnings",
        value: "0.00",
        key: "totalNumberOfDailyEarnings"
    },
    {
        title: "Today Success",
        value: "0",
        key: "totalNumberOfDailyEarnings"
    },
    {
        title: "Today Failed",
        value: "0",
        key: "totalNumberOfFailedTransactions"
    },
    {
        title: "Today Success Rate",
        value: "0",
        key: "successPct"
    },
    {
        title: "Total Earnings",
        value: "0",
        key: "totalNumberOfDailyEarnings"
    },
    {
        title: "Success Transactions",
        value: "0",
        key: "totalNumberOfSuccessfulTransaction"
    },
    {
        title: "Total Transactions",
        value: "0",
        key: "totalNumberOfDailyTransactions"
    },
    {
        title: "Pending",
        value: "0",
        key: "totalNumberOfDailyEarnings"
    }
];