import React, { Fragment, useEffect, useState } from 'react';
import "./header.scss";
import {handleLogout} from "../../utilities/utils";

function Header() {

    const [isDropdownVisible, toggleDropdown] = useState(false);
    const [userName, setUserName] = useState("");

    useEffect(()=> {
        let userDetails = sessionStorage.getItem("userDetails") || "{}";
        userDetails = JSON.parse(userDetails);
        let name = userDetails.username;
        setUserName(name);
    },[]);

    const toggleFullScreen = function() {
        let element = document.documentElement;
        if (document.fullscreenElement) {
            const requestMethod = document.exitFullscreen || document.webkitexitFullscreen || document.mozexitFullscreen || document.msexitFullscreen;

            if (requestMethod) { // Native full screen.
                requestMethod.call(document);
            } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
                var wscript = new ActiveXObject("WScript.Shell");
                if (wscript !== null) {
                    wscript.SendKeys("{F11}");
                }
            }
        } else {
            const requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;

            if (requestMethod) { // Native full screen.
                requestMethod.call(element);
            } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
                var wscript = new ActiveXObject("WScript.Shell");
                if (wscript !== null) {
                    wscript.SendKeys("{Esc}");
                }
            }
        }
    }

    return (
        <header className="page_header">
            <div className="logo_wrapper">
                <a href="/dashboard/">
                    <h5>PAYZ365</h5>
                </a>
            </div>
            <ul className="header_menus">
                <li className="maximize" title="Maximize" onClick={() => toggleFullScreen()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-maximize"><path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path></svg>
                </li>
                <li className="user_info"
                    onMouseEnter={() => toggleDropdown(true)}
                    onMouseLeave={() => toggleDropdown(false)}
                >
                    <Fragment>
                        <div>
                            <img src="http://payz365.com/controlpanel/assets/images/dashboard/manager-min 36x36.png"></img>
                        </div>
                        <div className="user_dropdown">
                            <span>{userName}</span>
                            <p className="mb-0 font-roboto">
                                Admin 
                                <i className="middle fa fa-angle-down"></i>
                            </p>        
                        </div>
                    </Fragment>
                    {isDropdownVisible ? (<ul className="user_actions">
                        <li onClick={handleLogout}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                            <span>Logout</span>
                        </li>
                    </ul>) : null }
                    
                </li>

            </ul>
        </header>
    );
}

export default Header;
