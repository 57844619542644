import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import "./settings.scss";
import { defaultFilters, defaultPagination, checkActiveCategoryUPI, columns} from "./data";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import Grid from "../../components/Grid";
import SubHeader from "../../components/SubHeader";
import Paginate from "../../components/Pagination";

function Settings() {
    const navigate = useNavigate();
    const [settingsList, updateSettingsList] = useState({settings: [], totalBankAccounts: 0});
    const [filters, updateFilters] = useState(defaultFilters)

    const fetchData = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.settingsList}`, 
            queryObject: filters
        }
        API.get(options).then(data => {
            checkActiveCategoryUPI(data.settings);
            updateSettingsList(data);
        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(()=> {
        fetchData();
    }, []);

    const handleSortingChange = useCallback(sortObj => {
        if(!sortObj.length) {
            return;
        }
        const {id, desc} = sortObj[0];
        filters.sortBy = id;
        filters.sortOrder = desc ? "desc" : "asc";
        updateFilters(filters);
        fetchData();
    }, []);

    const handlePaginationChange = (paginationObj) => {
        const {pageNumber, pageSize} = paginationObj;
        filters.pageNumber = (pageNumber-1).toString();
        filters.pageSize = pageSize;
        updateFilters(filters);
        fetchData();
    }

    const handleCellClick = (row, cell, event) => {
        let userRoles = sessionStorage.getItem("userDetails") || "{}";
        userRoles = JSON.parse(userRoles);
        const roles = userRoles.roles;
        if(roles.indexOf("UneditableSetting") == -1) {
            return;
        }
        
        let id = row.values.id;
        let data = settingsList.settings.filter(value => value.id == id);
        data = data.length ? data[0] : {}
        localStorage.setItem("settings", JSON.stringify(data));
        navigate(`/dashboard/settings/edit/${data.id}`)
    }

    return (
        <Fragment>
            <SubHeader pageHeader="Settings" />
            <div className="card_container">
                <Grid
                    columns={columns}
                    data = {settingsList.settings}
                    manualSortBy={true}
                    handleSortingChange={handleSortingChange}
                    handleTableCellClick={handleCellClick}
                />
                <div className="paginate_container">
                    <Paginate 
                        handleChange = {handlePaginationChange}
                        totalRecords = {settingsList.totalBankAccounts}
                        pageSize = {defaultPagination.pageSize}
                        currentPage = {filters.pageNumber+1}
                    />
                </div>
            </div>            
        </Fragment>
    );
}

export default Settings;
