import React from "react";
import "./utrThanks.scss";

const UTRThanks = () => {
    return (
        <div className="thanks-container">
            <div>UTR HAS BEEN SUCCESSFULLY SUBMITTED.</div>
            <div className="subtext"> Your amount will be credited within 1-2 minutes</div>
        </div>
    )
}

export default UTRThanks;