import React, {useState} from 'react';
import "./dateRangePicker.scss";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";


const RangePicker = (props) => {
    const {rangeStartDate, rangeEndDate, onChange, name} = props;

    const [dateRange, setDateRange] = useState([rangeStartDate, rangeEndDate]);
    const [startDate, endDate] = dateRange;

    const handleSelect = (value) => {
        setDateRange(value);

        if(value[0] && value[1]) {
            let formattedValue = [];
            formattedValue[0] = moment(value[0]).format().slice(0,-6)
            formattedValue[1] = moment(value[1]).add(1, 'days').format().slice(0,-6)
            onChange(name, formattedValue);
        }
        if(!value[0] && !value[1]) {
            onChange(name, value);
        }
    }

    return (
        <div className="date_range_picker">
            <DatePicker 
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                    handleSelect(update);
                }}
                placeholderText={'Date Range'} 
                isClearable
                maxDate={new Date()}
            />
        </div>
    )
};

export default RangePicker;