import React from 'react';
import { Routes, Route } from "react-router-dom"
import Login from "./pages/login";
import RequestForm from "./pages/requestForm";
import UTRPay from "./pages/utrPay";
import UTRThanks from "./pages/utrThanks";
import RouteComponent from "./containers/RouteComponent";
import "./index.scss";

function App() {
   return (
     <div className="App">
        <Routes>
          <Route index element={ <Login/> } />
          <Route path="login" element={ <Login/> } />  
          <Route path="request-form" element={ <RequestForm/> } />  
          <Route path="upay/:id" element={ <UTRPay/> } />  
          <Route path="utr-thanks" element={ <UTRThanks/> } />  
          <Route path="dashboard/*" element={ <RouteComponent/> } /> 
        </Routes>
     </div>
   )
}
export default App;