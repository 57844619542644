import React, { Fragment, useEffect, useState, useCallback } from 'react';
import "./failedReports.scss";
import { defaultFilters, defaultPagination, columns, filterConfig} from "./data";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import Grid from "../../components/Grid";
import SubHeader from "../../components/SubHeader";
import Paginate from "../../components/Pagination";
import Filters from "../../components/Filters";

function Transactions() {

    const [filters, setFilters] = useState(defaultFilters);
    const [transactions, setTransactions] = useState({transactions: [], totalTransactions: 0});
    const [filtersArr, updateFiltersArr] = useState(filterConfig);

    const fetchData = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.fetchFailedReport}`, 
            queryObject: filters
        }
        API.get(options).then(data => {
            setTransactions(data);
        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(()=> {
        fetchData();
    }, []);

    const handleSortingChange = useCallback(sortObj => {
        if(!sortObj.length) {
            return;
        }
        const {id, desc} = sortObj[0];
        filters.sortBy = id;
        filters.sortOrder = desc ? "desc" : "asc";
        setFilters(filters);
        fetchData();
    }, []);

    const handlePaginationChange = (paginationObj) => {
        const {pageNumber, pageSize} = paginationObj;
        filters.pageNumber = (pageNumber-1).toString();
        filters.pageSize = pageSize;
        setFilters(filters);
        fetchData();
    }

    const applyFilter = (filterObj) => {
        if (filterObj.daterange) {
            filterObj.startDateTime = filterObj.daterange[0];
            filterObj.endDateTime = filterObj.daterange[1];
            filterObj.dateRange == null;
        } else {
            filterObj.startDateTime = null;
            filterObj.endDateTime = null;
        }
        let updatedFilters = {...filterObj, ...defaultPagination}
        setFilters(updatedFilters);
        fetchData();
    }

    const downloadReport = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.downloadFailedReport}`, 
            queryObject: filters
        }
        API.download(options).then(blob => {
            let file = window.URL.createObjectURL(blob);
            window.location.assign(file);
        }).catch(error => {
            console.log(error)
        });
    }

    return (
        <Fragment>
            <SubHeader pageHeader="Failed Reports" />
            <div className="card_container">
                <Filters 
                    config = {filtersArr}
                    defaultFilters={filters}
                    applyFilter = {applyFilter}
                />
                <button className="download_button" onClick={downloadReport}>Download</button>
                <Grid
                    columns={columns}
                    data = {transactions.transactions}
                    manualSortBy={true}
                    handleSortingChange={handleSortingChange}
                />
                <div className="paginate_container">
                    <Paginate 
                        handleChange = {handlePaginationChange}
                        totalRecords = {transactions.totalTransactions}
                        pageSize = {defaultPagination.pageSize}
                        currentPage = {filters.pageNumber+1}
                    />
                </div>
            </div>            
        </Fragment>
    );
}

export default Transactions;
