export const formFields = [
    {
        name: "orderId",
        type: "text",
        width: "230px",
        placeholder: "Order Id",
        validations: [
            {
                type: "required",
                errorMessage: "Please Enter Order Id"
            }
        ]
    },
    {
        name: "amount",
        type: "text",
        width: "230px",
        placeholder: "Amount",
        validations: [
            {
                type: "required",
                errorMessage: "Please Enter Amount"
            }
        ]
    },
    {
        name: "category",
        type: "text",
        width: "230px",
        placeholder: "Category",
        validations: [
            {
                type: "required",
                errorMessage: "Please Enter Category"
            }
        ]
    },
];

export const buttons = [
    {
        name: "",
        type: "submit",
        label: "Submit",
        cssClass:"",
        handleClick: ()=>{} 
    }
];