export const defaultFilters = {
    account_id: "", 
    startDateTime: "",
    endDateTime: "",
    isClaimed: 0
};

export const filterConfig = [
    {
        name: "daterange",
        type: "dateRange",
        label: "Date Range",
        width: "230px",
        placeholder: "Date Range"
    },
    {
        name: "accountName",
        type: "select",
        label: "Select Account",
        width: "230px",
        options: [
        {label: "Select Account", value: ""},
        {label: "All Accounts", value: ""},
        ]
    }
]


