export const localization = {
    en: {
        "heading": "Payment",
        "ref_info": "Ref. No",
        "title_text": "Scan the QR code to pay",
        "upi_description": "Please Check your payment application for UTR number",
        "upi_helper": "How to find the UTR number ?",
        "note1": "Scan QR Code",
        "note2": "After paying from the following payment option: PayTM, PhonePE, GooglePay, BHIM,etc.",
        "note3": "Insert 12 digits UTR",
        "click_cta": "Click Here",
        "secure_text": "100% Secure Payments",
        "button": "Submit"

    },
    tamil: {
        "heading": "பணம் செலுத்துதல்",
        "ref_info": "Ref. இல்லை",
        "title_text": "பணம் செலுத்த QR குறியீட்டை ஸ்கேன் செய்யவும்",
        "upi_description": "உங்கள் கட்டண விண்ணப்பத்தை UTR எண்ணுக்குச் சரிபார்க்கவும்",
        "upi_helper": "UTR எண்ணை எப்படி கண்டுபிடிப்பது?",
        "note1": "QR குறியீட்டை ஸ்கேன் செய்யவும்",
        "note2": "பின்வரும் கட்டண விருப்பத்திலிருந்து பணம் செலுத்திய பிறகு: PayTM, PhonePE, GooglePay, BHIM போன்றவை.",
        "note3": "12 இலக்க UTR ஐச் செருகவும்",
        "click_cta": "இங்கே கிளிக் செய்யவும்",
        "secure_text": "100% பாதுகாப்பான கொடுப்பனவுகள்",
        "button": "சமர்ப்பிக்கவும்"
    },
    telgu: {
        "heading": "చెల్లింపు",
        "ref_info": "Ref. నం",
        "title_text": "చెల్లించడానికి QR కోడ్‌ని స్కాన్ చేయండి",
        "upi_description": "దయచేసి UTR నంబర్ కోసం మీ చెల్లింపు దరఖాస్తును తనిఖీ చేయండి",
        "upi_helper": "UTR సంఖ్యను ఎలా కనుగొనాలి?",
        "note1": "QR కోడ్‌ని స్కాన్ చేయండి",
        "note2": "కింది చెల్లింపు ఎంపిక నుండి చెల్లించిన తర్వాత: PayTM, PhonePE, GooglePay, BHIM, మొదలైనవి.",
        "note3": "12 అంకెల UTRని చొప్పించండి",
        "secure_text": "100% సురక్షిత చెల్లింపులు",
        "button": "సమర్పించండి"
    }
}