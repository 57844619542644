import React, { Fragment, useEffect, useState } from 'react';
import "./dashboard.scss";
import {metaData} from "./data";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import SubHeader from "../../components/SubHeader";
import Card from "../../components/Card";

function Home() {
    const [homeData, updateHomeData] = useState(metaData);

    const prepareData = (data) => {
        const currentData = [...homeData];
        currentData.forEach(section => {
            if(data[section.key]) {
                section.value = data[section.key];
            }
        });
        updateHomeData(currentData);
    };

    const fetchData = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.fetchDashboardData}`
        }
        API.get(options).then(data => {
            prepareData(data);
        }).catch(error => {
            console.log(error)
        });
    }
    
    useEffect(() => {
        fetchData();
    },[]);

    return (
        <Fragment>
            <SubHeader pageHeader="Dashboard" />
            <div className="home_container">
                { homeData.map((data,index) => {
                    return <Card {...data} key={index} />
                })}
            </div>
        </Fragment>
    );
}

export default Home;
