export const formFields = [
    {
        name: "bankName",
        type: "select",
        width: "230px",
        label: "Bank Name",
        placeholder: "Bank Name",
        options: [
            {label: "Select Bank", value: ""}
        ],
        validations: [
            {
                type: "required",
                errorMessage: "Please select Bank Name"
            }
        ]
    },
    {
        name: "accountName",
        value: "",
        label: "Account Name",
        placeholder: "Account Name",
        type: "text",
        cssClass: "",
        validations: [
            {
                type: "required",
                errorMessage: "Please fill in Account Name"
            }
        ]
    },
    {
        name: "upiId",
        value: "",
        placeholder: "UPI Id",
        label: "UPI",
        type: "text",
        cssClass: "",
        validations: [
            {
                type: "required",
                errorMessage: "Please fill in UPI Id"
            }
        ]
    },
    {
        name: "accountNumber",
        value: "",
        label: "Bank Account Number",
        placeholder: "Bank Account Name",
        type: "text",
        cssClass: "",
        validations: [
            {
                type: "required",
                errorMessage: "Please fill in Bank Account Number"
            }
        ]
    },
    {
        name: "targetDaily",
        value: "",
        label: "Target Daily",
        placeholder: "Target Daily",
        type: "text",
        cssClass: "",
        validations: [
            {
                type: "required",
                errorMessage: "Please fill in Daily Target"
            }
        ]
    },
    {
        name: "accountStatus",
        type: "select",
        width: "230px",
        label: "Select Status",
        options: [
            {label: "Select Status", value: ""},
            {label: "Active", value: "active"},
            {label: "Inactive", value: "inactive"}
        ],
        validations: [
            {
                type: "required",
                errorMessage: "Please select Status"
            }
        ]
    },
    {
        name: "file",
        type: "file",
        width: "230px",
        label: "Choose QR Code",
        placeholder: "Choose File",
        accept:"image/*",
        validations: [
            {
                type: "required",
                errorMessage: "Please choose a file"
            }
        ]
    }
];

export const buttons = [
    {
        name: "",
        type: "submit",
        label: "Submit",
        cssClass:"",
        handleClick: ()=>{} 
    }
];