import React from 'react';
import Pagination from 'rc-pagination';
import "rc-pagination/assets/index.css";
import "./pagination.scss";
import ReactPaginate from 'react-paginate';


const Paginate = (props) => {
    const {handleChange, totalRecords, pageSize, currentPage} = props;
    
    const handlePaginationChange = (e) => {
        handleChange({pageNumber:e.selected+1, pageSize})
    }

    return (
        // <Pagination 
        //     defaultCurrent = {1}
        //     onChange = {handlePaginationChange}
        //     total = {totalRecords}
        //     current={currentPage}
        //     pageSize={pageSize}
        // />

        <ReactPaginate
       
        containerClassName="flex justify-center items-center gap-10 my-10"
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePaginationChange}
        pageRangeDisplayed={5}
        pageCount={Math.ceil(totalRecords/pageSize)}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        activeClassName="bg-green-200"
      />

    );
};

export default Paginate;