import React from 'react';

export const defaultPagination = {
    pageNumber: "0",
    pageSize: 50
};

export const defaultFilters = {
    pageNumber: defaultPagination.pageNumber, 
    pageSize: defaultPagination.pageSize, 
    sortBy: "id",
    sortOrder: "desc"
};


export const columns = [
    {
      Header: "SR No",
      accessor: 'id',
      width: "42px"
    },
    {
      Header: "Category",
      accessor: 'category',
      width: "50px"
    },
    {
      Header: "Active UPI Id",
      accessor: 'activeUpi',
      width: "290px"
    },
    {
      Header: "Is Active",
      accessor: 'isActive',
      width: "94px"
    },
    {
      Header: "Action",
      accessor: '',
      width: "60px",
      Cell: ({ cell: { value } }) => <a style={{cursor: "pointer"}}>Edit </a>,
      disableSortBy: true,
      clickhandler: true
    }
];

export const checkActiveCategoryUPI = (categoryData) => {
    categoryData.forEach(category => {
        const {activeUpi ,mappedUpis} = category;
        let isActive = false;
        mappedUpis && mappedUpis.forEach(upi => {
            if (upi && upi.upi === activeUpi && upi.activeCategory) {
                isActive = true;
            }
        });
        category.isActive = isActive.toString();
    });
}