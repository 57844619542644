import React from 'react';
import { Routes, Route } from "react-router-dom"
import Transactions from "../../pages/transactions";
import Dashbaord from "../../pages/dashboard";
import StatementRecords from "../../pages/statementRecords";
import StatementManagement from "../../pages/statementManagement";
import BankAccounts from "../../pages/bankAccounts";
import CreateEditBankAccounts from "../../pages/createEditBankAccount";
import Settings from "../../pages/settings";
import EditSettings from "../../pages/editSettings";
import UnclaimedRecords from "../../pages/unclaimedRecords";
import FailedReports from "../../pages/failedReports";
import StatementReports from "../../pages/statementReports";
import TransactionReports from "../../pages/transactionReports";
import UnclaimedReports from "../../pages/unclaimedReports";
import HourlyReports from '../../pages/hourlyReports';
import AllReports from '../../pages/allReports';

import Headers from "../Header";
import Menubar from "../Menubar";

import "../../index.scss";

function RouteComponent() {
   return (
    <div className="App">
        <Headers/>
        <Menubar/>
        <div className="middle_container">
            <Routes path="dashboard">
                <Route path="" exact element = {<Dashbaord />} />
                <Route path="transactions" element={ <Transactions/> } />
                <Route path="statement-records" element={ <StatementRecords/> } />
                <Route path="statement-import" element={ <StatementManagement/> } />
                <Route path="bank-accounts" element={ <BankAccounts/> } />
                <Route path="bank-accounts/create/:id" element={ <CreateEditBankAccounts/> } />
                <Route path="bank-accounts/create" element={ <CreateEditBankAccounts/> } />
                <Route path="settings" element={ <Settings/> } />
                <Route path="settings/edit/:id" element={ <EditSettings/> } />
                <Route path="unclaimed-records" element={ <UnclaimedRecords/> } />
                <Route path="failed-records" element={ <FailedReports/> } />
                <Route path="statement-report" element={ <StatementReports/> } />
                <Route path="transaction-report" element={ <TransactionReports/> } />
                <Route path="unclaimed-report" element={ <UnclaimedReports/> } />
                <Route path="hourly-reports" element={ <HourlyReports/> } />
                <Route path="all-reports" element={ <AllReports/> } />
            </Routes>
        </div>   
    </div>
   )
}
export default RouteComponent;


// "scripts": {
//     "test": "echo \"Error: no test specified\" && exit 1",
//     "start": "set HTTPS=true && webpack-dev-server --mode development --open --hot",
//     "build": "NODE_ENV=production webpack"
//   },
// "scripts": {
//     "start": "react-scripts start",
//     "build": "react-scripts build",
//     "test": "react-scripts test",
//     "eject": "react-scripts eject"
//   },

// npx webpack --mode production