import React, { Fragment, useEffect, useState, useCallback } from 'react';
import "./hourlyReports.scss";
import { defaultFilters, defaultPagination, columns, filterConfig} from "./data";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import Grid from "../../components/Grid";
import SubHeader from "../../components/SubHeader";
import Paginate from "../../components/Pagination";

function HourlyReports() {

    const [filters, setFilters] = useState(defaultFilters);
    const [hourlyReports, setHourlyReports] = useState({hourlyReports: [], totalTransactions: 0});

    const fetchData = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.hourlyTransactions}`, 
            queryObject: filters
        }
        API.get(options).then(data => {
            setHourlyReports(data);
        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(()=> {
        fetchData();
    }, []);

    const handlePaginationChange = (paginationObj) => {
        const {pageNumber, pageSize} = paginationObj;
        filters.pageNumber = (pageNumber-1).toString();
        filters.pageSize = pageSize;
        setFilters({...filters});
        fetchData();
    }

    const handleSortingChange = useCallback(sortObj => {
        console.log(sortObj);
        // if(!sortObj.length) {
        //     return;
        // }
        // const {id, desc} = sortObj[0];
        // filters.sortBy = id;
        // filters.sortOrder = desc ? "desc" : "asc";
        // setFilters({...filters});
        // // fetchData();
    }, []);

    return (
        <Fragment>
            <SubHeader pageHeader="Last 24 Hourly Reports" />
            <div className="card_container">
                {hourlyReports.hourlyReports.length ? (
                    <div className="summary">
                        <h2>Last 15 Min Report</h2>
                        <div>
                            <span className="title">Pending : </span>
                            <span>{hourlyReports.hourlyReports[0]["pending"]}</span>
                        </div>
                        <div>
                            <span className="title">Success : </span>
                            <span>{hourlyReports.hourlyReports[0]["success"]}</span>
                        </div>
                        <div>
                            <span className="title">Failed : </span>
                            <span>{hourlyReports.hourlyReports[0]["failed"]}</span>
                        </div>
                        <div>
                            <span className="title">Success Rate : </span>
                            <span>{hourlyReports.hourlyReports[0]["successRate"]}</span>
                        </div>
                    </div>
                ) : null}
                <Grid
                    columns={columns}
                    data = {hourlyReports.hourlyReports}
                    manualSortBy={true}
                    handleSortingChange={handleSortingChange}
                />
                <div className="paginate_container">
                    <Paginate 
                        handleChange = {handlePaginationChange}
                        totalRecords = {hourlyReports.totalTransactions}
                        pageSize = {defaultPagination.pageSize}
                        currentPage = {filters.pageNumber+1}
                    />
                </div>
            </div>            
        </Fragment>
    );
}

export default HourlyReports;
