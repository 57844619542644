import React from 'react';
import "./subHeader.scss";

function SubHeader(props) {
    const {pageHeader, pageSubheader="Home"} = props;

    return (
        <div className="subheader">
            <h3>{pageHeader}</h3>
            <div className="description">
                <div>
                    <svg className="item_icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home item_icon"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                </div>
                <div>/</div>
                <div>
                    {pageHeader}
                </div>
                <div>/</div>
                <div>Home</div>
            </div>
        </div>
    )
}

export default SubHeader