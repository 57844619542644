export const handleLogout = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("userDetails");
    window.location.href = "/login"
}

export const prepareAuthHeader = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    return {
        Authorization: `Bearer ${accessToken}`
    }
}