import React, {Fragment, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import "./menubar.scss";

function MenuBar() {
    const [userRoles, updateUserRoles] = useState({});

    useEffect(()=> {
        let userDetails = sessionStorage.getItem("userDetails") || "{}";
        userDetails = JSON.parse(userDetails);
        const roles = userDetails.roles;
        const rolesObj = {};
        roles.forEach(role => {rolesObj[role] = true});
        updateUserRoles(rolesObj);
    },[]);

    return (
        <div className="menubar_container">
            <ul className="menu_list">
                {userRoles.Transactions ? (
                <li className="list_item">
                    <Link to="/dashboard/">
                        <div>
                            <svg className="item_icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home item_icon"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                            <span className="item_name">Dashboards</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                </li>) : null}
                {userRoles.Transactions ? (
                <li className="list_item">
                    <Link to="/dashboard/transactions">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-center item_icon">
                                <line x1="18" y1="10" x2="6" y2="10"></line>
                                <line x1="21" y1="6" x2="3" y2="6"></line>
                                <line x1="21" y1="14" x2="3" y2="14"></line>
                                <line x1="18" y1="18" x2="6" y2="18"></line>
                            </svg>
                            <span className="item_name">Transactions</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                </li>) : null}
                {userRoles.BankAccounts ? (
                <li className="list_item">
                    <Link to="/dashboard/bank-accounts">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-inbox item_icon">
                                <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
                                <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path>
                            </svg>
                            <span className="item_name">Bank Accounts</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                </li>) : null}
                {userRoles.Settings ? (
                <li className="list_item">
                    <Link to="/dashboard/settings">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings item_icon">
                                <circle cx="12" cy="12" r="3"></circle>
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                            </svg>
                            <span className="item_name">Settings</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                </li>) : null}
                {userRoles.StatementRecords ? (
                <li className="list_item">
                    <Link to="/dashboard/statement-records">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-justify item_icon">
                                <line x1="21" y1="10" x2="3" y2="10"></line>
                                <line x1="21" y1="6" x2="3" y2="6"></line>
                                <line x1="21" y1="14" x2="3" y2="14"></line>
                                <line x1="21" y1="18" x2="3" y2="18"></line>
                            </svg>
                            <span className="item_name">Statement Records</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                </li>) : null}
                {userRoles.UnclaimedRecords ? (
                <li className="list_item">
                    <Link to="/dashboard/unclaimed-records">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-left item_icon">
                                <line x1="17" y1="10" x2="3" y2="10"></line>
                                <line x1="21" y1="6" x2="3" y2="6"></line>
                                <line x1="21" y1="14" x2="3" y2="14"></line>
                                <line x1="17" y1="18" x2="3" y2="18"></line>
                            </svg>
                            <span className="item_name">unclaimed records</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                </li>) : null}
                {userRoles.HourlyReports ? (
                <li className="list_item">
                    <Link to="/dashboard/hourly-reports">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-left item_icon">
                                <line x1="17" y1="10" x2="3" y2="10"></line>
                                <line x1="21" y1="6" x2="3" y2="6"></line>
                                <line x1="21" y1="14" x2="3" y2="14"></line>
                                <line x1="17" y1="18" x2="3" y2="18"></line>
                            </svg>
                            <span className="item_name">24 hourly reports</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                </li>) : null}
                {userRoles.ReportManagement ? (
                <li className="list_item">
                    <Link to="/dashboard/all-reports">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-left item_icon">
                                <line x1="17" y1="10" x2="3" y2="10"></line>
                                <line x1="21" y1="6" x2="3" y2="6"></line>
                                <line x1="21" y1="14" x2="3" y2="14"></line>
                                <line x1="17" y1="18" x2="3" y2="18"></line>
                            </svg>
                            <span className="item_name">all reports</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                </li>) : null}
                {userRoles.FailedReports ? (
                <li className="list_item">
                    <Link to="/dashboard/failed-records">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text item_icon">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                <polyline points="14 2 14 8 20 8"></polyline>
                                <line x1="16" y1="13" x2="8" y2="13"></line>
                                <line x1="16" y1="17" x2="8" y2="17"></line>
                                <polyline points="10 9 9 9 8 9"></polyline>
                            </svg>
                            <span className="item_name">Failed report</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                </li>) : null}
                {userRoles.StatementManagement ? (
                <li className="list_item">
                    <Link to="/dashboard/statement-import">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-code item_icon">
                                <polyline points="16 18 22 12 16 6"></polyline>
                                <polyline points="8 6 2 12 8 18"></polyline>
                            </svg>
                            <span className="item_name">statement management</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                </li>) : null}
                {userRoles.ReportManagement ? (
                <li className="list_item">
                    <Link>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-download item_icon">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="7 10 12 15 17 10"></polyline>
                                <line x1="12" y1="15" x2="12" y2="3"></line>
                            </svg>
                            <span className="item_name">report management</span>
                        </div>
                        <div>
                            <i className="fa fa-angle-right right_arrow"></i>
                        </div>
                    </Link>
                    <ul>
                        <li className="list_item sub_list">
                            <Link to="/dashboard/transaction-report">
                                <div>
                                    <span className="item_name">- transaction report</span>
                                </div>
                            </Link >
                            <Link to="/dashboard/statement-report">
                                <div>
                                    <span className="item_name">- statement report</span>
                                </div>
                            </Link>
                            <Link to="/dashboard/unclaimed-report">
                                <div>
                                    <span className="item_name">- unclaimed report</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </li>) : null}
            </ul>
        </div>
    );
}

export default MenuBar;
