import React from 'react';

export const defaultPagination = {
    pageNumber: "0",
    pageSize: 50
};

export const defaultFilters = {
    pageNumber: defaultPagination.pageNumber, 
    pageSize: defaultPagination.pageSize, 
    sortBy: "id",
    sortOrder: "desc"
};


export const columns = [
    {
      Header: "SR No",
      accessor: 'id',
      width: "42px"
    },
    {
      Header: "Bank",
      accessor: 'bankName',
      width: "50px"
    },
    {
      Header: "UPI Id",
      accessor: 'upiId',
      width: "290px"
    },
    {
      Header: "Bank AC Number",
      accessor: 'accountNumber',
      width: "94px"
    },
    {
      Header: "Amount Remaining",
      accessor: 'pendingDaily',
      width: "80px",
    },
    {
      Header: "Status",
      accessor: 'accountStatus',
      width: "51px",
    },
    {
      Header: "Qr Code",
      accessor: 'qrCode',
      width: "60px",
      Cell: ({ cell: { value } }) => <img src={value} style={{width: "30px"}}/>,
      disableSortBy: true
    },
    {
      Header: "Action",
      accessor: 'category',
      width: "60px",
      Cell: ({ cell: { value } }) => <a style={{cursor: "pointer"}}>Edit </a>,
      disableSortBy: true,
      clickhandler: true
    }
];