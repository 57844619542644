export const defaultFilters = {
    account_id: "", 
    startDateTime: "",
    endDateTime: ""
};

export const filterConfig = [
    {
        name: "daterange",
        type: "dateRange",
        label: "Date Range",
        width: "230px",
        placeholder: "Date Range"
    },
    {
        name: "status",
        type: "select",
        label: "Select Status",
        width: "230px",
        options: [
          {label: "Select Status", value: ""},
          {label: "All Status", value: ""},
          {label: "Initiate", value: "Initiate"},
          {label: "Incomplete", value: "Incomplete"},
          {label: "Pending", value: "Pending"},
          {label: "Success", value: "Success"},
          {label: "Failed", value: "Failed"},
        ]
    },
    {
        name: "category",
        type: "select",
        label: "Select Category",
        width: "230px",
        options: [
          {label: "Select Category", value: ""},
          {label: "All Category", value: ""},
          {label: "P1", value: "P1"},
          {label: "P2", value: "P2"},
          {label: "P3", value: "P3"},
          {label: "P4", value: "P4"},
        ]
    },
    {
        name: "accountName",
        type: "select",
        label: "Select Account",
        width: "230px",
        options: [
        {label: "Select Account", value: ""},
        {label: "All Accounts", value: ""},
        ]
    },
    {
        name: "format",
        type: "select",
        label: "Select Format",
        width: "230px",
        options: [
        {label: "Select Formats", value: ""},
        {label: "External", value: "external"},
        {label: "Internal", value: "internal"},
        ]
    }
]


