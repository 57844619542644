import React from 'react';
import moment from "moment";

import Badge from "../../components/Badge";

export const defaultPagination = {
    pageNumber: "0",
    pageSize: 50
};

export const defaultFilters = {
    bank_id: "", 
    account_id: "", 
    is_claimed: "", 
    pageNumber: defaultPagination.pageNumber, 
    pageSize: defaultPagination.pageSize, 
    referenceNumber: ""
};

export const filterConfig = [
  {
    name: "bankId",
    type: "select",
    label: "Select Bank",
    width: "230px",
    options: [
      {label: "Select Bank", value: ""},
      {label: "All Banks", value: ""},
      {label: "Indus Bank", value: "1"},
      {label: "IDFC Bank", value: "2"},
      {label: "HDFC Bank", value: "3"},
      {label: "Bharatpe Bank", value: "4"},
      {label: "RBL Bank", value: "5"},
    ]
  },
  {
    name: "accountName",
    type: "select",
    label: "Select Account",
    width: "230px",
    options: [
      {label: "Select Account", value: ""},
      {label: "All Accounts", value: ""},
      {label: "1234567891234", value: "P1"},
      {label: "adsd", value: "2"},
      {label: "asdasd", value: "3"},
    ]
  },
  {
    name: "isClaimed",
    type: "select",
    label: "Select Claimed",
    width: "230px",
    options: [
      {label: "Select Claimed", value: ""},
      {label: "Claimed", value: "1"},
      {label: "Unclaimed", value: "0"},
    ]
  },
  {
    name: "referenceNumber",
    type: "text",
    label: "Reference Number",
    width: "230px",
    placeholder: "Reference Number"
  }
]

const remarkColumnBadgeType = {
  "Request pending for UTR verification": "Success",
  "UTR code do not match": "Danger"
}

export const columns = [
    {
      Header: "SR No",
      accessor: 'id',
      width: "30px"
    },
    {
      Header: "Bank Name",
      accessor: 'bankName',
      width: "58px",
      Cell: ({ cell: { value } }) => <Badge value={value} type={value}/>,
      disableSortBy: true
    },
    {
      Header: "Account Name",
      accessor: 'accountName',
      width: "150px",
      Cell: ({ cell: { value } }) => <Badge value={value} type={value}/>,
      disableSortBy: true
    },
    {
      Header: "Order Id",
      accessor: 'orderId',
      width: "52px"
    },
    {
      Header: "Transaction Date",
      accessor: 'transactionDate',
      width: "98px",
      Cell: ({ cell: { value } }) => <span>{moment(value).format("yyyy-MM-DD")}</span>,
    },
    {
      Header: "Transaction Number",
      accessor: 'transactionNumber',
      width: "108px",
    },
    {
      Header: "Amount",
      accessor: 'amount',
      width: "60px",
      disableSortBy: true
    },
    {
      Header: "isClaimed",
      accessor: 'isClaimed',
      width: "74px",
      Cell: ({ cell: { value } }) => <Badge value={value} type={value}/>,
      disableSortBy: true
    },
    {
      Header: "Date",
      accessor: 'createdAt',
      width: "62px",
      disableSortBy: true,
      Cell: ({ cell: { value } }) => <span>{moment(value).format("yyyy-MM-DD HH:mm:ss")}</span>,
    }
];