import React, { Fragment, useEffect, useState } from 'react'
import "../transactions.scss";
const Filters = ({ filters, setReturnedFilters }) => {

    const [finalFilters, setfinalFilters]  = useState({});

    const handleChange = (e)=>{
        // add filters 
        const [changedFilter] = filters.filter((filter)=>filter.name==e.target.id);
        
        if(changedFilter.type=='select'){
            if(changedFilter.options[0]==e.target.value || changedFilter.defaultOption ==e.target.value){
                const { [e.target.id]: _, ...updatedFilters } = finalFilters;
                setfinalFilters({...updatedFilters});
                
            }
            else{
            setfinalFilters({...finalFilters, [e.target.id] : e.target.value});
        }
        }

        if(changedFilter.type=='input'){
            if(e.target.value==''){
                const { [e.target.id]: _, ...updatedFilters } = finalFilters;
                setfinalFilters({...updatedFilters});
            }
            else{
            setfinalFilters({...finalFilters, [e.target.id] : e.target.value});

        }
        }
        if(changedFilter.type=='min-date' || changedFilter.type=='max-date' ){
            if(e.target.value==''){
                const { [e.target.id]: _, ...updatedFilters } = finalFilters;
                setfinalFilters({...updatedFilters});
            }
            else{
            setfinalFilters({...finalFilters, [e.target.id] : e.target.value});

        }
        }

    }

    
    const handleSubmit = ()=>{
        setReturnedFilters({...finalFilters});
    }
    
    return (
        <div className=" flex gap-5 flex-wrap ">

            {filters.map((filter, index) => {
                if (filter.type == 'select') {
                    return (
                        <select className='filters_dropdown w-56 ' id={filter.name} onChange={handleChange}>
                            <option value={filter.defaultOption}> {filter.defaultOption}</option>
                            {filter?.options?.map((value, index) => {
                                return <option value={value} key={index}> {value}</option>
                            })}
                            {/* Options for filter one */}
                        </select>
                    )
                }

                if (filter.type == 'input') {
                    return (
                        <input className='filters_inputs w-56 h-10 self-center' type="text"  placeholder={filter.placeholder}  id={filter.name} onChange={handleChange} />
                    )
                }

                if (filter.type == 'min-date') {
                    return (
                        <div className='flex gap-3'>
                        <span className='self-center'> Min Date</span>
                        <input className='  filters_inputs w-56 h-10 self-center' type="date" onChange={handleChange} placeholder={filter.placeholder}  id={filter.name} />
                        </div>
                       
                      
                    )
                }
                if (filter.type == 'max-date') {
                    return (
                        <div className='flex gap-3'>
                        <span className='self-center'>Max Date</span>
                        <input className='filters_inputs w-56 h-10 self-center' type="date" onChange={handleChange} placeholder={filter.placeholder}  id={filter.name} />
                        </div>
                    )
                }

                if (filter.type == 'submit') {
                    return (
                        <button className={filter.classNames} onClick={handleSubmit} >Search</button>
                    )
                }
            })}



        </div>
    )
}

export default Filters

