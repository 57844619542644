import React, { useEffect, useState } from 'react';
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import SubHeader from "../../components/SubHeader";
import "./createEditBankAccount.scss";
import Form from "../../components/Forms";
import {formFields, buttons} from "./data";
import { useNavigate, useParams } from "react-router-dom";

function CreateEditBankAccount() {
    const navigate = useNavigate();

    const [fields, updateFormFields] = useState(formFields);
    const [uploadSuccess, updateUploadSuccess] = useState(false);
    const [uploadError, updateUploadFailure] = useState("");
    const [bankDetails, updateBankDetails] = useState({});

    const {id} = useParams();

    const updateValuesToEdit = () => {
        let bankDetails = localStorage.getItem("bankAccount");
        localStorage.removeItem("bankAccount");
        bankDetails = JSON.parse(bankDetails);
        updateBankDetails(bankDetails);
    }

    const fetchData = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.statementImporMetaData}`
        }
        API.get(options).then(data => {
            const formFields = [...fields];
            formFields.forEach(field => {
                if(field.name === "bankName") {
                    field.options = [{label: "Select Bank", value: ""}];
                    data.forEach(option => {
                        field.options.push({label: option.bankName, value: option.shortBankName});
                    })
                }
                if(field.name == "file" && id) {
                    field.validations = [];
                }
            })
            updateFormFields(formFields);
            setTimeout(updateValuesToEdit, 500);
        }).catch(error => {
            console.log(error)
        });   
    }

    useEffect(()=> {
        fetchData();
    }, []);

    const handleFormSubmit = (data) => {
        updateUploadFailure("");
        updateUploadSuccess(false);
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
        formData.delete("accountStatus");
        formData.delete("file");
        formData.append("file", data["file"][0]);
        formData.append("status", data["accountStatus"]);
        let url = `${env.apiDomain}${ApiPaths.createBank}`
        if(id) {
            url = `${env.apiDomain}${ApiPaths.updateBank}`
            formData.append("oldAccountNumber", bankDetails.accountNumber);
            formData.append("oldBankName", bankDetails.bankName);
        }
        const options = {
            url,
            requestBody: formData,
        }
        API.postFormData(options).then(data => {
            updateUploadSuccess(true);
            setTimeout(()=> {
                navigate("/dashboard/bank-accounts")
            }, 1000);
        }).catch(error =>  {
            updateUploadFailure(error.message);
        });
    }

    return (
        <div className="create_edit_bank_account">
            <SubHeader pageHeader="Bank Account Management" />
            <div className="card_container">
                <h5>Create Bank Account</h5>
                <Form 
                    formFields = {fields}
                    buttons= {buttons}
                    onSubmit={handleFormSubmit}
                    defaultValues={bankDetails}
                />
                {uploadError && <div className="upload_error">{uploadError ? uploadError : "Something went wrong"}</div>}
                {uploadSuccess && <div className="upload_success">{uploadSuccess ? "Bank Created Successfully" : ""}</div>}
            </div>
        </div>
    );
}

export default CreateEditBankAccount;
