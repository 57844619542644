import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import "./bankAccounts.scss";
import { defaultFilters, defaultPagination, mockData, columns} from "./data";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import Grid from "../../components/Grid";
import SubHeader from "../../components/SubHeader";
import Paginate from "../../components/Pagination";

function BankAccounts() {
    const navigate = useNavigate();
    const [bankAccounts, updateBankAccounts] = useState({bankAccount: [], totalBankAccounts: 0});
    const [filters, updateFilters] = useState(defaultFilters)

    const fetchData = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.fetchBankAccounts}`, 
            queryObject: filters
        }
        API.get(options).then(data => {
            updateBankAccounts(data);
        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(()=> {
        fetchData();
    }, []);

    const handleSortingChange = useCallback(sortObj => {
        if(!sortObj.length) {
            return;
        }
        const {id, desc} = sortObj[0];
        filters.sortBy = id;
        filters.sortOrder = desc ? "desc" : "asc";
        updateFilters(filters);
        fetchData();
    }, []);

    const handlePaginationChange = (paginationObj) => {
        const {pageNumber, pageSize} = paginationObj;
        filters.pageNumber = (pageNumber-1).toString();
        filters.pageSize = pageSize;
        updateFilters(filters);
        fetchData();
    }

    const createAccount = () => {
        navigate("/dashboard/bank-accounts/create")
    }

    const handleCellClick = (row, cell, event) => {
        let id = row.values.id;
        let data = bankAccounts.bankAccount.filter(value => value.id == id);
        data = data.length ? data[0] : {}
        localStorage.setItem("bankAccount", JSON.stringify(data));
        navigate(`/dashboard/bank-accounts/create/${data.id}`)
    }

    return (
        <Fragment>
            <SubHeader pageHeader="Bank Account Management" />
            <div className="card_container">
                <button type="button" className="create_account" onClick={createAccount}>Create Bank Account</button>
                <Grid
                    columns={columns}
                    data = {bankAccounts.bankAccount}
                    manualSortBy={true}
                    handleSortingChange={handleSortingChange}
                    handleTableCellClick={handleCellClick}
                />
                <div className="paginate_container">
                    <Paginate 
                        handleChange = {handlePaginationChange}
                        totalRecords = {bankAccounts.totalBankAccounts}
                        pageSize = {defaultPagination.pageSize}
                        currentPage = {filters.pageNumber+1}
                    />
                </div>
            </div>            
        </Fragment>
    );
}

export default BankAccounts;
