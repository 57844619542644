export const ApiPaths = {
    login: "public/auth/signin",
    transactions: "api/transaction",
    statementRecords: "api/statement",
    statementMetaData: "api/statement/metadata",
    statementImporMetaData: "api/bank/metadata",
    uploadStatement: "uploadFile",
    createOrder: "api/order/submit",
    getDetails:"api/order/detail",
    submitUTR: "api/order/utr",
    fetchBankAccounts: "api/bank/list",
    updateBank: "api/bank/edit",
    createBank: "api/bank/create",
    settingsList: "api/settings/list",
    editSettings: "api/settings/create",
    fetchDashboardData: "api/dashboard",
    fetchFailedReport: "api/transaction/failed",
    downloadFailedReport: "api/transaction/failed/export",
    freezeOrder: "api/order/freeze",
    changeTransactionStatus: "api/transaction/status/edit",
    exportStatement: "api/statement/export",
    exportTransaction: "api/transaction/export",
    hourlyTransactions: "api/transaction/hourly",
    exportAllTransactions: "api/transaction/success/export"
}


