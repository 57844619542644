export const formFields = [
    {
        name: "category",
        value: "",
        label: "Category",
        placeholder: "Category",
        type: "text",
        cssClass: "",
        validations: [
            {
                type: "required",
                errorMessage: "Please fill in Category"
            }
        ]
    },
    {
        name: "upiLevel1",
        type: "select",
        width: "230px",
        label: "UPI Level 1",
        options: [
            {label: "Select UPI Id", value: ""}
        ]
    },
    {
        name: "upiLevel2",
        type: "select",
        width: "230px",
        label: "UPI Level 2",
        options: [
            {label: "Select UPI Id", value: ""}
        ]
    },{
        name: "upiLevel3",
        type: "select",
        width: "230px",
        label: "UPI Level 3",
        options: [
            {label: "Select UPI Id", value: ""}
        ]
    },{
        name: "upiLevel4",
        type: "select",
        width: "230px",
        label: "UPI Level 4",
        options: [
            {label: "Select UPI Id", value: ""}
        ]
    },{
        name: "upiLevel5",
        type: "select",
        width: "230px",
        label: "UPI Level 5",
        options: [
            {label: "Select UPI Id", value: ""}
        ]
    },{
        name: "upiLevel6",
        type: "select",
        width: "230px",
        label: "UPI Level 6",
        options: [
            {label: "Select UPI Id", value: ""}
        ]
    },{
        name: "upiLevel7",
        type: "select",
        width: "230px",
        label: "UPI Level 7",
        options: [
            {label: "Select UPI Id", value: ""}
        ]
    },{
        name: "upiLevel8",
        type: "select",
        width: "230px",
        label: "UPI Level 8",
        options: [
            {label: "Select UPI Id", value: ""}
        ]
    },{
        name: "upiLevel9",
        type: "select",
        width: "230px",
        label: "UPI Level 9",
        options: [
            {label: "Select UPI Id", value: ""}
        ]
    },{
        name: "upiLevel10",
        type: "select",
        width: "230px",
        label: "UPI Level 10",
        options: [
            {label: "Select UPI Id", value: ""}
        ]
    },
];

export const buttons = [
    {
        name: "",
        type: "submit",
        label: "Submit",
        cssClass:"",
        handleClick: ()=>{} 
    }
];