import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./login.scss";
import Paths from "../../configs/staticPaths"
import Forms from "../../components/Forms";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";

function Login() {
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState("");
    const formFields = [
        {
            name: "username",
            value: "",
            label: "Email Address",
            type: "email",
            cssClass: "",
            validations: [
                {
                    type: "required",
                    errorMessage: "Please fill in Email Address"
                }
            ]
        },
        {
            name: "password",
            value: "",
            label: "Password",
            type: "password",
            cssClass: "",
            validations: [
                {
                    type: "required",
                    errorMessage: "Please fill in Password"
                }
            ]
        }
    ];

    const buttons = [
        {
            name: "",
            type: "submit",
            label: "Sign In",
            cssClass:"loginButton text-white bg-blue-800",
            handleClick: ()=>{} 
        }
    ];

    const handleFormSubmit = (data) => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.login}`,
            requestBody: {
                email: data.username,
                password: data.password
            },
            includeAuth: false
        }
        API.post(options).then(data => {
            const {accessToken, email, username, roles, categories } = data;
            let userDetails = {email, username, roles};
            sessionStorage.setItem("accessToken", accessToken);
            sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
            sessionStorage.setItem("userCategories", JSON.stringify(categories));
            navigate("/dashboard")
        }).catch(error =>  {
            setLoginError(error.message);
        });
    };

    return (
        <div className="container">
            <div className="imageContainer">
                <img className="loginImage" src={`${Paths.image}/login/pexels-croberin-photography-1422408-min.jpg`} alt="looginpage"/>
            </div>
            <div className="formContainer">
                <div className="loginCard">
                    <div>
                        <div className="heading">
                            <h2>SelectnPay</h2>
                        </div>
                        <div className="formBox">
                            <h4 className="title">Sign in to account</h4>
                            <p className="description">Enter your email & password to login</p>
                            <Forms 
                                formFields = {formFields}
                                parentClass=""
                                buttons= {buttons}
                                onSubmit={handleFormSubmit}
                            />
                        </div>
                        <div className="login_error">{loginError ? loginError : ""}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login