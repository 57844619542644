import React, { useState, Fragment, useEffect } from 'react';
import "./unclaimedReports.scss";
import {defaultFilters, filterConfig} from "./data";
import moment from "moment";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import SubHeader from "../../components/SubHeader";
import Filters from "../../components/Filters";

function UnclaimedReports() {
    const [filters, setFilters] = useState({...defaultFilters});

    const [filtersArr, updateFiltersArr] = useState(filterConfig);

    const updateFiltersConfig = (metaData) => {
        const {accounts} = metaData;
        const currentFilterArr = [...filtersArr];
        currentFilterArr.forEach(filter => {
            if(filter.name == "accountName") {
                filter.options = [
                    {label: "Select Account", value: ""},
                    {label: "All Accounts", value: ""},
                ];
                accounts && accounts.forEach(account => {
                    filter.options.push({label: account, value: account},)
                })
            }
        });
        updateFiltersArr(currentFilterArr); 
    };

    const fetchData = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.statementMetaData}`
        }
        API.get(options).then(data => {
            updateFiltersConfig(data);
        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(()=>{
        fetchData();
    } , []);

    const downloadReport = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.exportStatement}`,
            queryObject: filters
        }
        API.download(options).then(blob => {
            let file = window.URL.createObjectURL(blob);
            window.location.assign(file);
        }).catch(error => {
            console.log(error)
        });
    }

    const applyFilter = (filterObj) => {
        if (filterObj.daterange) {
            filterObj.startDateTime = filterObj.daterange[0];
            filterObj.endDateTime = filterObj.daterange[1];
            filterObj.dateRange == null;
        } else {
            filterObj.startDateTime = null;
            filterObj.endDateTime = null;
        }
        filterObj.isClaimed = "0";
        setFilters(filterObj);
        downloadReport();
    }

    return (
        <Fragment>
            <SubHeader pageHeader="Unclaimed Download Reports" />
            <div className="card_container statement_management">
                <Filters 
                    config = {filtersArr}
                    defaultFilters={filters}
                    applyFilter = {applyFilter}
                    button="download"
                />
            </div>            
        </Fragment>
    )
}

export default UnclaimedReports;