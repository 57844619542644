import React, { useState, Fragment, useEffect } from 'react';
import "./statementManagement.scss";
import {ApiPaths} from "../../configs/apiConfig";
import {env} from "../../configs/env";
import API from "../../utilities/Api";
import Forms from "../../components/Forms";
import SubHeader from "../../components/SubHeader";
import {formFields, buttons} from "./data";

function StatmentManagement() {

    const [fields, updateFormFields] = useState(formFields);
    const [uploadSuccess, updateUploadSuccess] = useState(false);
    const [uploadError, updateUploadFailure] = useState("");
    const [uploadProgress, updateUploadProgress] = useState(false);
    const [lastUploadTime, updateLastUploadTime] = useState("");

    let metaData = [];

    const fetchData = () => {
        const options = {
            url: `${env.apiDomain}${ApiPaths.statementImporMetaData}`
        }
        API.get(options).then(data => {
            metaData = data;
            const formFields = [...fields];
            formFields.forEach(field => {
                if(field.name === "bankName") {
                    field.options = [{label: "Select Bank", value: ""}];
                    data.forEach(option => {
                        field.options.push({label: option.bankName, value: option.shortBankName});
                    })
                }
            })
            updateFormFields(formFields);
        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(()=>{
        fetchData();
    } , [])

    const handleFieldUpdate = (fieldObj) => {
        const {name, value} = fieldObj;
        const fieldValue = value[name];
        metaData.forEach(bank => {
            if(bank.shortBankName == fieldValue) {
                updateLastUploadTime(bank.uploadTime);
            }
        })
    }

    const handleFormSubmit = (data) => {
        updateUploadFailure("");
        updateUploadSuccess(false);

        const {bankName, statemetn} = data;
        const formData = new FormData();
        formData.append("file", statemetn[0]);

        const options = {
            url: `${env.apiDomain}${ApiPaths.uploadStatement}`,
            queryObject: {bankName},
            requestBody: formData,
        }
        updateUploadProgress(true);
        API.postFormData(options).then(data => {
            updateUploadSuccess(true);
        }).catch(error =>  {
            updateUploadFailure(error.message);
        }).finally(() => {
            updateUploadProgress(false);
        });
    };

    return (
        <Fragment>
            <SubHeader pageHeader="Statement Import" />
            <div className="card_container statement_management">
                <Forms 
                    formFields = {fields}
                    parentClass=""
                    buttons= {buttons}
                    onSubmit={handleFormSubmit}
                    onFieldChange={handleFieldUpdate}
                />
                { lastUploadTime ? <div className="last_upload_time">{`Last Statement uploaded date: ${lastUploadTime}`}</div> : null }
                <div className="upload_progress">{uploadProgress ? "Uploading the file, please wait. Do not refresh the screen" : ""}</div>
                <div className="upload_error">{uploadError ? uploadError : ""}</div>
                <div className="upload_success">{uploadSuccess ? "File Uploaded Successfully" : ""}</div>
            </div>            
        </Fragment>
    )
}

export default StatmentManagement;