import React, { useState } from 'react';
import "./filters.scss";
import DateRangePicker from "../DateRangePicker";

function Filters(props) {
    const {config, defaultFilters, applyFilter, button="search"} = props;

    const [filterObj, updateFilterObj] = useState(defaultFilters);

    const handleFilterSubmit = () => {
        applyFilter(filterObj);
    }

    const handleFilterChange = (filter, event) => {
        filterObj[filter] = event.target.value;
        updateFilterObj(filterObj);
    }

    const handleDateFilterChange = (filter, values) => {
        if(values[0] == null && values[1] == null) {
            values = null;
        }
        filterObj[filter] = values;
        updateFilterObj(filterObj);
    }

    const getFieldsDOM = () => {
        const fieldsDOM = config.map((field, index) => {
            const {name, type, cssClass, placeholder, width, options, startDateTime, endDateTime} = field;
            
            if(field.type === "select") {
                return (
                    <div key={index} className="filter_group" style={{width: width || "100%"}}>
                        <select name={name} className={`selectField ${cssClass}`} onChange={(event) => handleFilterChange(name, event)}>
                            {
                                options.map((option, index) => (
                                    <option value={option.value }  key={index}>{option.label}</option>
                                ))
                            }
                        </select>
                    </div>
                )
            } else if(field.type === "dateRange") {
                return (
                    <div key={index} className={`filter_group ${cssClass}`} style={{width: width || "100%"}}>
                        <DateRangePicker 
                            rangeStartDate={startDateTime}
                            rangeEndDate={endDateTime}
                            name={name}
                            onChange={handleDateFilterChange}
                        />
                    </div>
                )
            } else {
                return (
                    <div key={index} className="filter_group" style={{width: width || "100%"}}>
                        <input 
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            className={`inputField ${cssClass}`}
                            onChange={(event) => handleFilterChange(name, event)}
                        />
                    </div>
                );
            };
        });
        return fieldsDOM;    
    }

    
    return (
        <div className="filter_container">
            {getFieldsDOM()}
            { button === "search" ? 
                (<button type="button" onClick={handleFilterSubmit} className="apply_button">Search</button>) : (
                    <button type="button" onClick={handleFilterSubmit} className="apply_button">Download</button>
                )
            }
        </div>
    );
};

export default Filters;